<template>
  <div>
    <v-toolbar class="pa-3" flat>
      <v-app-bar-nav-icon>
        <v-icon size="56px" color="#286aff">mdi-water-circle</v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title>
        <span>Celestine</span>
        <span>The most reliable and efficent data breach search engine</span>
      </v-toolbar-title>
      <template v-if="$vuetify.breakpoint.width > 1300">
        <v-spacer></v-spacer>
        <div class="nav-items">
          <span class="nav-item" @click="$router.push('/about')">
            About us
          </span>

          <span class="nav-item" @click="$router.push('/support')">
            Support
          </span>

          <span class="nav-item" @click="$router.push('/tos')">
            Terms of Services
          </span>
          <!-- <span class="nav-item" v-if="this.auth" @click="$router.push('/admin')"> Admin </span> -->
        </div>

        <v-spacer></v-spacer>

        <div class="nav-items">
          <span
            class="nav-item alt_delim"
            v-if="!this.auth"
            @click="$router.push('/auth')"
          >
            Login
          </span>

          <span class="nav-item no_delim" @click="$router.push('/contact')">
            Contact
          </span>

          <span
            class="nav-item button no_delim"
            @click="$router.push('/auth/signup')"
          >
            Try now</span
          >

          <span class="nav-item no_delim ml-5">
            <v-checkbox
              class="pt-3"
              color="secondary"
              v-model="$vuetify.theme.dark"
              off-icon="mdi-theme-light-dark"
              on-icon="mdi-theme-light-dark"
            ></v-checkbox>
          </span>
        </div>
      </template>

      <template v-if="$vuetify.breakpoint.width <= 1300">
        <v-spacer></v-spacer>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab plain color="primary" small v-bind="attrs" v-on="on">
              <v-icon size="30" dark> mdi-menu </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="$router.push('/auth')" link>
              <v-list-item-title>Login</v-list-item-title>
            </v-list-item>
            <v-list-item @click="$router.push('/auth/signup')" link>
              <v-list-item-title>Signup</v-list-item-title>
            </v-list-item>

            <v-list-item link>
              <v-list-item-title>Contact</v-list-item-title>
            </v-list-item>

            <v-list-item link>
              <v-list-item-title>Terms of Services</v-list-item-title>
            </v-list-item>

            <v-list-item link>
              <v-list-item-title>About us</v-list-item-title>
            </v-list-item>

            <v-list-item link>
              <v-list-item-title>Support</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-toolbar>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  name: "Navigation",

  data() {
    return {
      auth: "",
    };
  },
  mounted() {
    var token = localStorage.token;
    this.auth = false;
    if (token) {
      var user = Vue.prototype.$jwt(token);
      console.log(user.email);
      if (!user) this.auth = false;
      this.auth = true;
      if (Math.floor(Date.now() / 1000) > user.exp) {
        this.auth = false;
        localStorage.clear();
      }
    }
  },
};
</script>

<style lang="scss">
.v-toolbar__content {
}
.nav-items {
  display: flex;
  flex-direction: row;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 17px;
  align-items: center;
  transition: 0.5s;

  .nav-item {
    margin: 0px 10px;
    user-select: none;
    transition: 0.5s;
    i {
      font-size: 32px;
      margin-top: 7px;
    }

    &.button {
      padding: 10px 35px;
      border-radius: 5px;
      color: white;
      font-size: 16px;
      background: #286aff;
    }

    &.no_delim:not(:last-of-type):after {
      content: "";
      margin: 0;
    }

    &.alt_delim:not(:last-of-type):after {
      content: "|";
      margin-left: 20px;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }

    &:not(:last-of-type):after {
      content: ".";
      margin-left: 20px;
      font-weight: 900;
    }
  }
}

.v-toolbar__title {
  font-family: "Poppins";
  padding-left: 10px !important;
  display: flex;
  flex-direction: column;

  span:first-of-type {
    font-size: 22px;
    font-weight: 600;
  }

  span:last-of-type {
    font-size: 12px;
  }
}
</style>