<template>
  <v-app>
    <Navigation />
    <canvas v-if="$vuetify.breakpoint.width > 1000" id="nokey"> </canvas>

    <v-container class="heading-container">
      <v-row fill-height justify="center">
        <transition name="slide-from-top">
          <v-col v-show="startAnimation" cols="12" align="center">
            <div class="center-content d-flex flex-column">
              <span class="main-content">Celestine</span>
              <span class="secondary-content"
                >The most reliable and efficent data breach search engine.</span
              >
            </div>
          </v-col>
        </transition>
      </v-row>

      <v-row fill-height justify="center" style="margin-top: 140px">
        <v-col cols="12" align="center">
          <div class="center-content d-flex flex-column">
            <span class="secondary-content primary-color"
              >Why should you choose us?</span
            >
          </div>
        </v-col>
      </v-row>

      <v-row justify="center" align="center">
        <transition name="slide-from-left">
          <v-col
            v-show="startAnimation"
            cols="12"
            sm="12"
            md="6"
            lg="4"
            align="center"
          >
            <v-card
              class="why_card mx-auto my-12"
              max-width="350"
              min-width="350"
            >
              <v-card-title class="d-flex flex-column justify-flex-start">
                <span class="contact-title">ATO Prevention</span>
              </v-card-title>
              <v-card-text class="text-center mt-3">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </v-card-text>
            </v-card>
          </v-col>
        </transition>
        <transition name="scale">
          <v-col
            v-show="startAnimation"
            cols="12"
            sm="12"
            md="6"
            lg="4"
            align="center"
          >
            <v-card
              class="why_card mx-auto my-12"
              max-width="350"
              min-width="350"
            >
              <v-card-title class="d-flex flex-column justify-flex-start">
                <span class="contact-title">ATO Prevention</span>
              </v-card-title>
              <v-card-text class="text-center mt-3">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </v-card-text>
            </v-card>
          </v-col>
        </transition>

        <transition name="slide-from-right">
          <v-col
            v-show="startAnimation"
            cols="12"
            sm="12"
            md="6"
            lg="4"
            align="center"
          >
            <v-card
              class="why_card mx-auto my-12"
              max-width="350"
              min-width="350"
            >
              <v-card-title class="d-flex flex-column justify-flex-start">
                <span class="contact-title">ATO Prevention</span>
              </v-card-title>
              <v-card-text class="text-center mt-3">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </v-card-text>
            </v-card>
          </v-col>
        </transition>
      </v-row>

      <v-row fill-height justify="center" >
        <transition name="fade">
          <v-col v-show="startAnimation" cols="12" align="center">
            <v-btn style="color: white !important" color="#4d73ff"
              >Learn more about us</v-btn
            >
          </v-col>
        </transition>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import Navigation from "./components/Navigation";
export default {
  name: "Home",

  created() {
    if(this.$vuetify.breakpoint.width < 1273)
      this.shouldAnimated = false
  },


  mounted() {
    this.startAnimation = true;
  },

  data() {
    return {
      startAnimation: false,
      shouldAnimate: true,
    };
  },

  components: {
    Navigation,
  },
};
</script>

<style lang="scss">

.why_card {
  font-family: "Poppins";
  opacity: 0.9;
  padding: 5px;
  border-radius: 10px !important;
  background: var(--v-support_cards_bg-base) !important;
}

.heading-container {
  margin-top: 100px;
}

.center-content {
  z-index: 1;
  font-family: "Poppins";

  span.primary-color {
    font-weight: 600;
    color: var(--v-primary-base) !important;
  }
  .secondary-content {
    font-size: 23px;
    color: var(--v-secondary-base);
    font-weight: 400;
  }

  .main-content {
    font-family: "Ubuntu";
    font-size: 52px;
    color: var(--v-primary-base);
    font-weight: 600;
  }
}

body {
  overflow-y: hidden;
}

html,
body {
  overflow-x: hidden;
  background: var(--v-bg-base) !important;

  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgb(0, 0, 0);
    border-radius: 15px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgb(12, 12, 12);
  }
}
#nokey {
  margin-top: 80px;
  overflow: hidden !important;
  position: absolute;
  z-index: 0;
}
</style>
